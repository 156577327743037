<template lang="pug">
  div(class="form-documents-mass-share")
    h2(class="title-32px mb-8") {{ $t('Share these documents') }}
    v-form(@submit.prevent.stop="submit")
      ValidationObserver(ref="form" slim)
        div(
          v-for="(participant,idx) in participants"
          :key="idx"
          class="form-documents-mass-share__participant"
          :class="{ 'with-delete': participants.length > 1 }"
        )
          ValidationProvider(
            :rules="setIdentifierRules(participant, idx)"
            :vid="`identifier${idx}`"
            v-slot="{ errors }"
            :name="wrapQuotesProviderName($t('Email or Edprou'))"
            mode="lazy"
          )
            v-text-field(
              v-model.trim="participant.identifier"
              filled
              hide-details="auto"
              :error-messages="errors"
              :label="$t('Enter user email or EDRPOU')"
            )
          ValidationProvider(
            rules="required"
            :vid="`action${idx}`"
            v-slot="{ errors }"
            :name="wrapQuotesProviderName($t('What have to do'))"
            mode="lazy"
          )
            v-select(
              v-model="participant.action"
              :items="formattedParticipantActions"
              :label="$t('What have to do')"
              filled
              hide-details="auto"
              :error-messages="errors"
              :menuProps="{ bottom: true, offsetY: true }"
            )
          v-btn(
            v-if="participants.length > 1"
            class="secondary-button error-btn"
            width="48"
            icon
            @click="deleteParticipant(idx)"
          )
            e-svg-icon danger-trash
        v-btn(
          text
          class="form-documents-mass-share__add-btn mt-4"
          @click="addParticipant"
        ) {{ $t('Add participant') }}
      div(class="form-documents-mass-share__actions pt-6")
        v-btn(
          height="48"
          @click="closeModal(false)"
        ) {{ $t('Cancel') }}
        v-btn(
          type="submit"
          class="main-button"
          :loading="loading"
        ) {{ $t('Share') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import participants from '~/modules/documents/mixins/participants'
import validate from '~/mixins/validation/validate'

export default {
  name: 'FormDocumentsMassShare',
  components: {
    ESvgIcon,
    ValidationProvider
  },
  mixins: [participants, validate],
  props: {
    model: {
      type: Function,
      default: () => ({})
    },
    documents: {
      type: Array,
      default: () => []
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      participants: [{ identifier: null, action: null }],
      loading: false
    }
  },
  computed: {
    formattedParticipants () {
      return this._.map(this.participants, (participant) => {
        const formattedParticipant = { action: participant.action }
        const isEdrpou = isFinite(participant.identifier) && participant.identifier.length >= 8 && participant.identifier.length <= 10
        if (isEdrpou) {
          formattedParticipant.edrpou = participant.identifier
        } else {
          formattedParticipant.email = participant.identifier
        }
        return formattedParticipant
      })
    },
    canFillDocuments () {
      return this.documents.every(doc => doc.canFillFields)
    },
    formattedParticipantActions () {
      if (this.canFillDocuments) {
        return this.participantActions
      }
      return this._.filter(this.participantActions, action => action.value !== 'fill')
    }
  },
  methods: {
    addParticipant () {
      this.participants.push({ identifier: null, action: null })
    },
    deleteParticipant (idx) {
      this.participants.splice(idx, 1)
    },
    async submit () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      try {
        this.loading = true
        const payload = {
          documentIds: this._.map(this.documents, item => item.id),
          participants: this.formattedParticipants
        }
        await this.model.api().shareDocuments(payload)
        this.$notification.success(this.$t('Documents have successfully sent'))
        this.closeModal(true)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    setIdentifierRules (participant, idx) {
      return {
        required: true,
        email: !isFinite(participant.identifier) || !(participant.identifier?.length >= 8 && participant.identifier?.length <= 10),
        uniqueArrayValue: { array: this.participants, uniqueKey: 'identifier', index: idx }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-documents-mass-share {
  &__participant {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    padding: 12px;

    border: 1px dashed rgba(18, 18, 18, 0.12);
    border-radius: 12px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &.with-delete {
      grid-template-columns: 1fr 180px 48px;
    }
  }
  &__add-btn {
    color: $primary;
  }
  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
