const uploadValidation = {
  computed: {
    extensionsMap () {
      return {
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        doc: 'application/msword',
        pdf: 'application/pdf',
        txt: 'text/plain',
        png: 'image/png',
        jpeg: 'image/jpeg',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        xls: 'application/vnd.ms-excel'
      }
    },
    acceptedFileExtensions () {
      return Object.values(this.extensionsMap).join(',')
    },
    rules () {
      return 'ext:jpg,jpeg,png,doc,docx,xls,xlsx,pdf,txt|size:35000'
    }
  }
}

export default uploadValidation
