<template lang="pug">
  v-form(class="change-name")
    h2(class="title-32px mb-6") {{ $t('Change full name') }}
    ValidationObserver(ref="changeNameForm")
      ValidationProvider(
        rules="required|min:3"
        mode="lazy"
        v-slot="{ errors }"
        name="Full name"
        vid="fullName"
      )
        v-text-field(
          v-model="fullName"
          :error-messages="errors"
          filled
          :label="$t('Enter full name')"
        )
    div(class="change-name__actions")
      v-btn(
        class="flex-grow-1"
        height="48"
        @click="closeModal(false)"
      ) {{ $t('Cancel') }}
      v-btn(
          class="main-button flex-grow-1"
          height="48"
          :loading="loading"
          @click="submit"
      ) {{ $t('Change') }}
</template>

<script>
import User from '~/models/administration/User'
import responsive from '~/mixins/pages/responsive'

export default {
  name: 'FormChangeName',
  mixins: [responsive],
  props: {
    closeModal: {
      type: Function,
      default: () => ({})
    },
    currentName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    fullName: '',
    loading: false
  }),
  created () {
    this.fullName = this.currentName
  },
  methods: {
    async submit () {
      const valid = await this.$refs.changeNameForm.validate()

      if (valid) {
        try {
          this.loading = true
          await User.api().updateUserProfile({ name: this.fullName })
          this.closeModal(true)
          this.$notification.success(this.$t('Name has been changed successfully'))
        } catch (e) {
          this.$handlers.error(e, this)
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.change-name {
  color: $dark;

  &__actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 16px;
    padding-top: 16px;

    @media (min-width: map-get($breakpoints, 'md')) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: none;
    }
  }
}
</style>
