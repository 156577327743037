<template lang="pug">
  div(class="template-upload")
    template(v-if="loading")
      div(class="template-upload__loader")
        e-progress-circular(
          size="xl"
          width="5"
          color="#8F257D"
        )
    template(v-else)
      h2(class="title-32px mb-8") {{ $t('Create template') }}
      div(class="template-upload__content")
        div(class="template-upload__left")
          div(class="text-16px template-upload__label") {{ $t('Templates gallery') }}
          v-list(:disabled="templatesLoading")
            v-list-item-group(v-model="activeCategory")
              v-list-item(
                v-for="category in templateCategories"
                :key="category.id"
                class="list__item"
              ) {{ category.name }}
        div(class="template-upload__right")
          e-template-uploader(@close="closeModal")
          div(class="text-16px dark mt-8 mb-4") {{ $t('Create or select ready template') }}
          div(class="template-upload__templates" :class="{ 'loading': templatesLoading }")
            div(v-if="templatesLoading" class="template-upload__loader md")
              e-progress-circular(
                size="lg"
                width="4"
                color="#8F257D"
              )
            template(v-else)
              block-templates-card-upload(:close-modal="closeModal")
              block-templates-card(
                v-for="card in templateCards"
                :key="card.id"
                :item="card"
                :selected-id="selectedTemplateId"
                @select="selectTemplate"
              )
          div(v-if="selectedTemplateId" class="template-upload__footer")
            v-btn(
              :loading="btnLoading"
              class="main-button"
              @click="cloneTemplate"
              ) {{ $t('Select') }}
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Gallery from '~/modules/templates/models/Gallery'
import GalleryCategory from '~/modules/templates/models/GalleryCategory'
import ETemplateUploader from '~/modules/templates/components/elements/e-template-uploader'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import BlockTemplatesCard from '~/modules/templates/components/blocks/block-templates-card'
import BlockTemplatesCardUpload from '~/modules/templates/components/blocks/block-templates-card-upload'

export default {
  name: 'MTemplateUpload',
  components: {
    BlockTemplatesCardUpload,
    EProgressCircular,
    ETemplateUploader,
    BlockTemplatesCard
  },
  props: {
    closeModal: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      templatesLoading: false,
      btnLoading: false,
      activeCategory: 0,
      selectedTemplateId: null
    }
  },
  computed: {
    templateCategories () {
      const defaultCategories = [{ id: uuidv4(), name: this.$t('Recommended') }]
      return this._.concat(defaultCategories, GalleryCategory.all())
    },
    templateCards () {
      return Gallery.all()
    }
  },
  watch: {
    activeCategory (index) {
      const activeCategoryItem = this.templateCategories[index]
      const activeCategoryItemId = this._.get(activeCategoryItem, 'id', null)
      const params = {}
      const isRecommended = index === 0
      if (isRecommended) {
        params.recommended = true
      } else {
        params['category.id'] = activeCategoryItemId
      }
      this.fetchGalleryTemplates(params)
    }
  },
  created () {
    this.onCreated()
  },
  methods: {
    selectTemplate (id) {
      this.selectedTemplateId = id
    },
    async onCreated () {
      try {
        this.loading = true
        if (!GalleryCategory.exists()) {
          await this.fetchGalleryCategories()
        }
        await this.fetchGalleryTemplates({ recommended: true })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    },
    async fetchGalleryCategories () {
      try {
        await GalleryCategory.api().all()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async fetchGalleryTemplates (params) {
      this.selectedTemplateId = null
      try {
        this.templatesLoading = true
        await Gallery.api().filter(params).all()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.templatesLoading = false
      }
    },
    async cloneTemplate () {
      try {
        this.btnLoading = true
        const templateId = this._.get(await Gallery.api().cloneTemplate(this.selectedTemplateId), 'response.data.templateId')
        if (templateId) {
          this.$router.push({ path: `/templates/${templateId}` })
        }
        this.closeModal()
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.btnLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.template-upload {

  &__loader {
    width: 100%;
    min-height: 400px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    &.md {
      min-height: auto;
    }
  }

  &__content {
    width: 100%;

    display: flex;
    align-items: flex-start;
    gap: 40px;
  }

  &__left {
    max-width: 215px;
    width: 100%;

    position: sticky;
    top: 0;
  }

  &__right {
    width: 100%;
    position: relative;
  }

  &__label {
    padding-left: 16px;
  }

  &__templates {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
    padding-bottom: 60px;

    &.loading {
      grid-template-columns: 1fr;
    }
  }

  &__footer {
    height: 80px;
    background: $white;
    border-top: 1px solid $border-secondary;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: sticky;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 5;

    .v-btn {
      width: 160px;
    }
  }
}
</style>
