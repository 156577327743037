import { Model } from '@vuex-orm/core'
import APIPlatformConnector from '~/plugins/vuex-orm/plugins/_drivers/APIPlatformConnector'
import { contexts } from '~/const/global'

export default {
  install (components, options) {
    components.Model.apiConfig = {
      actions: {
        withType (params) {
          this.params = { ...this.params, filterBy: params }

          return this
        },

        forGroup (group) {
          this.params = Model._.merge(this.params, { filterBy: { forGroup: group.primaryVal } })

          return this
        },

        parent (params) {
          this.params = { ...this.params, filterBy: params }

          return this
        },

        root () {
          this.params = { ...this.params, root: true }

          return this
        },

        active () {
          this.params = { ...this.params, active: true }

          return this
        },

        filter (filter) {
          if (typeof this.params === 'object') {
            this.params.filter = { ...this.params.filter || {}, ...filter }
          } else {
            this.params = {
              filter
            }
          }

          return this
        },

        multiFilter (filter) {
          // When using this method, pass keys of filter object according to backend contract
          const formattedFilter = {}
          Object.entries(filter).map(([key, array]) => {
            if (array.length > 1) {
              array.forEach((value, idx) => {
                const paramKey = `${key}[${idx}]`
                formattedFilter[paramKey] = value
              })
            } else if (array.length === 1) {
              formattedFilter[key] = array[0]
            }
          })

          if (typeof this.params === 'object') {
            this.params.filter = { ...this.params.filter || {}, ...formattedFilter }
          } else {
            this.params = {
              filter: formattedFilter
            }
          }

          return this
        },

        rangeFilter (filter = {}) {
          const formattedDateFilter = {}

          Object.entries(filter).map(([key, array]) => {
            if (array.length === 1) {
              formattedDateFilter['date[after]'] = array[0]
            } else if (array.length > 1) {
              formattedDateFilter['date[before]'] = array[1]
              formattedDateFilter['date[after]'] = array[0]
            }
          })

          if (typeof this.params === 'object') {
            this.params.filter = { ...this.params.filter || {}, ...formattedDateFilter }
          } else {
            this.params = {
              filter: formattedDateFilter
            }
          }

          return this
        },

        exists (fields, exist = true) {
          const exists = 'exists[' + fields.join(', ') + ']'
          this.params = { ...this.params, [exists]: exist }
          return this
        },

        within (model, property = Model.$routes[this.model.entity].list(), params = {}) {
          const connector = new APIPlatformConnector(this.model, { ...this.params })
          return this.get(Model.$routes[this.model.entity].within(model, property), Object.assign({}, connector.config, params))
        },

        all (params, config = {}) {
          const connector = new APIPlatformConnector(this.model, { ...params, ...this.params })
          return this.get(config.route || Model.$routes[this.model.entity].list(), {
            ...connector.config,
            ...config
          })
        },

        create (payload, morph = true, config = {}) {
          return this.post(
            config.route || Model.$routes[this.model.entity].list(),
            morph ? this.model.deepMorph(payload) : payload,
            config
          )
        },

        update (model, payload, morph = true) {
          const connector = new APIPlatformConnector(this.model, { ...this.params }, contexts.update)
          return this.put(
            Model.$routes[this.model.entity].concrete(model.$id),
            morph ? this.model.deepMorph(payload) : payload,
            connector.config)
        },

        del (model) {
          return this.delete(Model.$routes[this.model.entity].concrete(model.$id), { delete: model[this.model.primaryKey] })
        },
        read (id, config = {}) {
          const connector = new APIPlatformConnector(this.model, { ...this.params }, contexts.read)
          return this.get(
            Model.$routes[this.model.entity].concrete(id), {
              ...connector.config,
              ...config
            }
          )
        },

        // TODO: REMOVE IT!
        async add (currency, payload) {
          await this.put(
            this.model.$routes[currency.$self().entity].exchangeRates(currency.id),
            { exchangeRates: [this.model.deepMorph(payload)] }, {
              dataTransformer: ({ data, headers }) => {
                return data.exchangeRates
              },
              persistBy: 'insertOrUpdate',
              persistOptions: {
                insertOrUpdate: ['exchangerates', 'exchangetypes', 'currencies']
              }
            })
        },

        // TODO: rewrite?
        onlyRootLevel () {
          const connector = new APIPlatformConnector(this.model, { ...this.params })
          return this.get(this.model.$routes[this.model.entity].onlyRootLevel(), connector.config)
        },

        // TODO: rewrite?
        children (id) {
          const connector = new APIPlatformConnector(this.model, { ...this.params })
          return this.get(this.model.$routes[this.model.entity].children(id), connector.config)
        },

        lastPrimaryKey () {
          return this.get(this.model.$routes[this.model.entity].lastPrimaryKey(), { save: false })
        },

        synchronize (payload) {
          return this.post(this.model.$routes[this.model.entity].synchronize(), payload, { save: false })
        }
      }
    }
  }
}
