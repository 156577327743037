<template lang="pug">
  v-col(cols="12" :class="classList")
    div(v-if="!loading && showProfile" class="t-orm-page__profile-wrapper")
      m-support-buttons(v-if="!xsDevice")
      block-profile-menu(v-if="!xsDevice")
    e-overlay-block(
      v-if="loading"
      :loading="loading"
      progress-width="6"
      progress-size="xxl"
      color="#161b25"
    )
    slot(v-if="!loading")

    m-info-block(v-if="'info' in slots && !loading" type='warning' class="mt-4")
      slot(name="info")
</template>

<script>
import { mapGetters } from 'vuex'
import MInfoBlock from '~/components/modules/blocks/m-info-block'
import EButtonHelp from '~/components/elements/buttons/e-button-help'
import responsive from '~/mixins/pages/responsive'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'
import MSupportButtons from '~/components/modules/m-support-buttons'
import BlockProfileMenu from '~/modules/profile/views/components/block-profile-menu'

export default {
  components: {
    MSupportButtons,
    EOverlayBlock,
    MInfoBlock,
    EButtonHelp,
    BlockProfileMenu
  },
  mixins: [responsive],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    supportButtons: {
      type: Boolean,
      default: true
    },
    showProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'profile'
    ]),
    classList () {
      return {
        't-orm-page': true,
        mobile: this.xsDevice
      }
    },
    slots () {
      return this.$slots
    }
  }
}
</script>

<style lang="scss">
  .t-orm-page {
    height: 100%;
    position: relative;

    &.mobile {
      padding-top: 15px;
      margin-bottom: 55px;
      background: $white;
      border-radius: 16px 16px 0 0;
    }

    &__payment-reminder {
      font-family: 'mariupol-regular', sans-serif;
      font-size: 0.69rem;
      color: #FF002B;
      border: 2px solid #FF002B;
      border-radius: 10px;
      font-weight: 700;
      padding: 5px;
      width: 100%;
      display: inline;
    }

    &__title {
      font-size: 1.25rem;
      font-weight: 500;

      @media (min-width: map-get($breakpoints, 'md')) {
        font-size: 1.75rem;
      }
    }

    &__profile-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 12px;
      right: 16px;
      z-index: 1;
    }
  }
</style>
