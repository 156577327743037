<template lang="pug">
  v-dialog(
    :content-class="contentClass"
    v-bind="$attrs"
    v-on="$listeners"
    :persistent="persistent"
    :scrollable="true"
  )
    div(v-if="$attrs.value" :class="cardClass")
      div(class="t-dialog__card-inner")
        e-overlay-block(
          v-if="loading"
          :loading="true"
          progress-size="xl"
          progress-width="5"
          absolute
        )
        e-button-close(
          v-if="showCloseBtn"
          x-small
          class="t-dialog__close-btn"
          @click="closeBtnClick"
        )
        div(
          v-if="('title' in $slots) || 'subtitle' in $slots"
          :class="{ 'mb-3': 'title' in $slots }"
        )
          v-layout(wrap align-center)
            v-flex(grow)
              div(class="d-flex justify-center")
                slot(name="icon")
              h3(class="title font-weight-bold wb-bw" v-if="'title' in $slots")
                slot(name="title")
              h5(class="font-weight-bold wb-bw" v-if="'subtitle' in $slots")
                slot(name="subtitle")
        div(
          v-if="'content' in $slots"
          class="pa-0 content"
          :class="{ 'no-padding': contentWithoutPadding }"
        )
          slot(name="content")
        div(
          v-if="'actions' in slots"
          class="pa-0 mt-3"
        )
          slot(name="actions")
</template>

<script>
import EButtonClose from '~/components/elements/buttons/e-button-close'
import EOverlayBlock from '~/components/elements/overlays/e-overlay-block'

export default {
  components: {
    EOverlayBlock,
    EButtonClose
  },
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: 'md',
      validator: value => value.match('xs|sm|md|lg')
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => {
        return ['default', 'card', 'confirmation', 'form', 'assign'].includes(value)
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
    onCloseBtn: {
      type: Function,
      default: null
    }
  },
  computed: {
    slots () {
      return this.$slots
    },
    cardClass () {
      return [
        ['t-dialog__', this.type].join('')
      ]
    },
    contentClass () {
      let classes = ''

      if ('content-class' in this.$attrs) {
        classes += this.$attrs['content-class']
      } else {
        classes += ' t-dialog'
      }

      if ('fullscreen' in this.$attrs) {
        classes += ' t-dialog__fullscreen'
      }

      if (!('fullscreen' in this.$attrs) && this.size) {
        classes += ` t-dialog__${this.size}`
      }

      return classes
    },
    contentWithoutPadding () {
      return ('title' in this.$slots || 'subtitle' in this.$slots) && 'actions' in this.$slots
    }
  },
  watch: {
    '$attrs.value' (val) {
      if (val) {
        if (this.showCloseBtn) {
          // eslint-disable-next-line nuxt/no-globals-in-created
          document.addEventListener('keyup', this.onKeyUp)
        }
        this.$emit('onOpen')
      } else {
        this.$emit('onClose')
      }
    }
  },
  methods: {
    closeBtnClick () {
      if (this.onCloseBtn && typeof this.onCloseBtn === 'function') {
        this.onCloseBtn()
      } else {
        this.$emit('input', false)
      }
      document.removeEventListener('keyup', this.onKeyUp)
    },
    onKeyUp (e) {
      if (e.key === 'Escape') {
        this.closeBtnClick()
      }
    }
  }
}
</script>

<style lang="scss">
.t-dialog {
  max-width: 1264px;
  width: 100%;
  margin: 15px;
  overflow: hidden;
  border-radius: 24px;

  @media (min-width: map-get($breakpoints, 'sm')) {
    margin: 24px;
  }

  &__xs {
    width: 100%;
    max-width: 425px;

    .t-dialog__default {
      > .v-card {
        &__title {
          padding: 52px 30px 0 !important;
        }

        &__text {
          padding: 4px 30px 0 !important;
        }

        &__actions {
          padding: 0 30px 52px !important;
        }
      }
    }
  }

  &__sm {
    @media (min-width: map-get($breakpoints, 'md')) {
      max-width: 45%;
    }
  }

  &__md {
    @media (min-width: map-get($breakpoints, 'md')) {
      max-width: 65%;
    }
  }

  &__lg {
    @media (min-width: map-get($breakpoints, 'md')) {
      max-width: 85%;
    }
  }

  &__fullscreen {
    max-width: unset;
    width: 100% !important;

    > .v-card {
      border-radius: 0 !important;
    }

    > .v-card.t-dialog__assign {
      padding: 30px !important;
    }
  }

  &__card-inner {
    padding: 16px;
    position: relative;

    @media (min-width: map-get($breakpoints, 'md')) {
      padding: 24px;
    }
    @media (min-width: map-get($breakpoints, 'lg')) {
      padding: 40px;
    }
  }

  &__default {
    overflow: auto;
  }

  &__close-btn {
    position: absolute !important;
    top: 12px;
    right: 12px;
    z-index: 2;
  }

  .v-overlay {
    border-radius: $card-border-radius;
  }
}

.content {
  font-size: 1rem !important;
  color: #000000 !important;
}
</style>
