const platforms = {
  computed: {
    isIOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
    }
  }
}

export default platforms
