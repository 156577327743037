import Base64js from 'base64-js'
import { Model } from '@vuex-orm/core'
import { get } from 'lodash/object'
import { EndUser } from '~/lib/EUSignWidget/eusign'
import { IntervalRequest } from '~/services/_utils/IntervalRequest'
import EdsKeyData from '~/services/EdsKey/EdsKeyData'

let storage = {}
const apiPrefix = '/api/v1'

class EdsIitCheckbox extends Model {
  static entity = '_edsiitcheckbox';

  static deleteData () {
    this.deleteAll()
    storage = {}
  }

  static fields () {
    return {
      id: this.uid(),
      type: this.attr(null),
      qrUrl: this.attr(null),
      title: this.attr(null),
      helpers: this.attr(null),
      diiaDeepLinkLoading: this.attr(null)
    }
  }

  stopIntervalRequest () {
    if (storage.intervalRequest) {
      storage.intervalRequest.stop()
    }
  }

  // deprecated method in Dubidoc (v1)
  async signByDiia (dataToSign, options = {}) {
    const { endUser, title, keyCheck = true, isHashed = true } = options
    const { axios, organization } = (this.helpers || {})
    let content = Base64js.fromByteArray(new TextEncoder().encode(String(dataToSign)))

    if (ArrayBuffer.isView(dataToSign)) {
      content = Base64js.fromByteArray(dataToSign)
    }
    const apiPrefix = '/api/v1'
    const deepLinkData = await axios.post(apiPrefix + '/cloud-signature/diia/deeplink', {
      name: title || 'Підпис запиту до ДПС',
      content,
      isHashed
    })
    const { id, deepLink } = get(deepLinkData, 'data', {})
    const pingSignUrl = apiPrefix + '/cloud-signature/' + id + '/ping'
    const internalSignUrl = apiPrefix + '/cloud-signature/' + id + '/external-signature'
    await this.saveModelData({
      qrUrl: deepLink
    })
    storage.intervalRequest = new IntervalRequest(async () => {
      return await axios.get(pingSignUrl)
    }, {
      interval: 2e3, // 2 seconds
      maxDelay: 3e5 // 5 minutes
    })
    const resolveCondition = res => get(res, 'data.status') === 'signed'
    const signed = get(await storage.intervalRequest.start(resolveCondition), 'data.status')
    if (signed !== 'signed') {
      return null
    }
    const signedData = get(await axios.get(internalSignUrl), 'data.signature', null)
    const keyData = await endUser.GetSigner(Base64js.toByteArray(signedData), 0)
    const edsKeyData = new EdsKeyData([keyData])
    if (!keyData) {
      throw new Error('We could not read the key data. Please try again or choose a different key')
    } else if (keyCheck && edsKeyData.identity !== get(organization, 'edrpou')) {
      throw new Error('The USREOU codes do not match. Please choose another')
    }
    return signedData
  }

  async signListByDiia (dataToSign, options = {}) {
    const { endUser, keyCheck = true, returnLink = null } = options
    const { axios, organization } = (this.helpers || {})

    const files = dataToSign.map((file) => {
      let fileContent = Base64js.fromByteArray(new TextEncoder().encode(String(file.content)))
      if (ArrayBuffer.isView(file.content)) {
        fileContent = Base64js.fromByteArray(file.content)
      }
      return { ...file, content: fileContent }
    })

    const deepLinkData = await axios.post(apiPrefix + '/cloud-signature/diia/deeplink-v2', {
      returnLink,
      files
    })
    const { id, deepLink } = get(deepLinkData, 'data', {})
    const pingSignUrl = apiPrefix + '/cloud-signature/' + id + '/ping'
    const internalSignUrl = apiPrefix + '/cloud-signature/' + id + '/external-signatures'

    await this.saveModelData({
      qrUrl: deepLink
    })
    storage.intervalRequest = new IntervalRequest(async () => {
      return await axios.get(pingSignUrl)
    }, {
      interval: 2e3, // 2 seconds
      maxDelay: 3e5 // 5 minutes
    })
    const resolveCondition = res => get(res, 'data.status') === 'signed'
    const signed = get(await storage.intervalRequest.start(resolveCondition), 'data.status')
    if (signed !== 'signed') {
      return null
    }
    const signedData = get(await axios.get(internalSignUrl), 'data', null)
    for (const signedItem of signedData) {
      const keyData = await endUser.GetSigner(Base64js.toByteArray(get(signedItem, 'signature')), 0)
      const edsKeyData = new EdsKeyData([keyData])
      if (!keyData) {
        throw new Error('We could not read the key data. Please try again or choose a different key')
      } else if (keyCheck && edsKeyData.identity !== get(organization, 'edrpou')) {
        throw new Error('The USREOU codes do not match. Please choose another')
      }
    }
    return signedData
  }

  async sign (dataToSign, options = {}) {
    const { onInterfaceActionError, endUser, isSmartId, isDiia } = (storage[this.type] || {})
    const { fallbackMethod, title, isHashed = true } = options

    try {
      if (isSmartId || isDiia || title) {
        await this.saveModelData({
          title,
          qrUrl: null
        })
      }
      if (isDiia) {
        if (Array.isArray(dataToSign)) {
          return await this.signListByDiia(dataToSign, { ...options, endUser })
        } else {
          return await this.signByDiia(dataToSign, { ...options, endUser })
        }
      }

      if (isHashed) {
        return await endUser.SignHash(EndUser.SignAlgo.DSTU4145WithGOST34311, dataToSign, true, true)
      } else {
        return await endUser.SignData(dataToSign, true, true)
      }
    } catch (e) {
      console.error(e)
      if (e?.code === 12) {
        return
      }
      await this.delete()
      if (typeof onInterfaceActionError === 'function') {
        await onInterfaceActionError(e)
      }
      if (typeof fallbackMethod === 'function') {
        await fallbackMethod()
      }
    }
  }

  async envelopData (certs = [], dataToEnvelope) {
    const { onInterfaceActionError, endUser } = (storage[this.type] || {})
    try {
      return await endUser.EnvelopData(certs, dataToEnvelope, false, true, true, true)
    } catch (e) {
      console.error(e)
      if (e?.code === 12) {
        return
      }
      await this.delete()
      if (typeof onInterfaceActionError === 'function') {
        await onInterfaceActionError(e)
      }
    }
  }

  saveModelData (data) {
    if (!this) {
      return
    }
    this.$update(data)
  }

  saveData (data = {}) {
    const savedData = storage[this.type] || {}
    storage[this.type] = {
      ...savedData,
      ...data,
      sign: async (...params) => {
        return await this.sign(...params)
      },
      envelopData: async (...params) => {
        return await this.envelopData(...params)
      }
    }
  }

  delete () {
    const signInterface = storage[this.type]
    if (signInterface) {
      signInterface.keyData = null
      signInterface.isSeal = false
      signInterface.isSmartId = false
      signInterface.isDiia = false
      signInterface.confirmed = false
    }
  }

  getEndUser () {
    const signInterface = storage[this.type]
    return signInterface?.endUser
  }

  getEndUserKM () {
    const signInterface = storage[this.type]
    return signInterface?.endUserKM
  }

  getInterface () {
    const signInterface = storage[this.type]
    if (signInterface?.confirmed) {
      return signInterface
    }
    return null
  }
}

export default EdsIitCheckbox
