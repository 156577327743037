<template lang="pug">
  e-button-upload-file(
    ref="uploader"
    :validation-rules="rules"
    :accept="acceptedFileExtensions"
    :button-class="xsDevice ? 'mobile-fixed-button' : 'w-100 mb-6'"
    multiple
    :loading="loading"
    @onload="upload"
  )
    e-svg-icon(v-if="xsDevice") plus
    span(v-else) {{ $t('Download') }}
</template>

<script>
import Base64js from 'base64-js'
import converters from '~/mixins/methods/converters'
import Document from '~/modules/documents/models/Document'
import EButtonUploadFile from '~/components/elements/buttons/e-button-upload-file'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import responsive from '~/mixins/pages/responsive'
import uploadValidation from '~/modules/templates/mixins/uploadValidation'

export default {
  name: 'EButtonDocumentUpload',
  components: {
    EButtonUploadFile,
    ESvgIcon
  },
  mixins: [uploadValidation, converters, responsive],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async upload (files) {
      const { valid, errors } = await this.$refs.uploader.validate(files)
      if (!valid) {
        const errMessage = this._.get(errors, '[0]')
        this.$notification.error(errMessage)
        return
      }
      try {
        this.loading = true
        for (const file of files) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              file: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('') // TODO: or just file.name ?
            }
            const documentId = this._.get(await Document.api().create(payload, true, { save: this.$route.name === 'documents' }), 'response.data.id')

            if (files && files.length === 1) {
              await this.$router.push({ path: `/documents/${documentId}` })
              window.dataLayer && window.dataLayer.push({
                event: 'document_downloaded',
                email: this._.get(this.$User, 'email')
              })
              return
            }

            this.$notification.success(this.$t('{document} has been successfully uploaded', { document: file.name }))
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(this.$t('Error occurred with {document} - {error}', { document: file.name, error: message }))
          }
        }
        await this.$store.dispatch('documents/updateDocumentsWithSavedParams')
        window.dataLayer && window.dataLayer.push({
          event: 'document_downloaded',
          email: this._.get(this.$User, 'email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
