<template lang="pug">
  label
    slot
      div(
        class="template-uploader"
        :class="{ 'failed': hasError, 'loading': loading }"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
      )
        div(class="d-flex flex-column justify-center align-center")
          div(
            class="title-20px mb-1"
            :class="{'errored': hasError }"
          ) {{ title }}
          div(class="text-14px text-center mb-6") {{ text }}
          v-btn(
            v-if="!loading"
            class="main-button sm"
            @click="openFileMenu"
          ) {{ $t('Choose file') }}
    ValidationProvider(:rules="rules" :name="$t(label)" ref="uploader")
      v-file-input(
        ref="file"
        type="file"
        class="template-uploader__input"
        :accept="acceptedFileExtensions"
        @change="upload($event)"
      )
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Base64js from 'base64-js'
import Template from '~/modules/templates/models/Template'
import converters from '~/mixins/methods/converters'
import uploadValidation from '~/modules/templates/mixins/uploadValidation'

export default {
  name: 'ETemplateUploader',
  components: {
    ValidationProvider
  },
  mixins: [uploadValidation, converters],
  props: {
    label: {
      type: String,
      default: 'Template uploader'
    }
  },
  data: () => ({
    loading: false,
    dragover: false,
    hasError: false,
    uploadingFileName: null,
    uploadingFileLength: null
  }),
  computed: {
    title () {
      if (this.hasError) {
        return this.$t('Upload error')
      } else if (this.loading) {
        return this.$t('Loading..')
      }
      return this.$t('Drag files here')
    },
    text () {
      if (this.hasError) {
        return this.$t('Unfortunately, we do not support this document type, or an unexpected error occurred')
      } else if (this.loading) {
        if (this.uploadingFileName) {
          return this.uploadingFileName
        }
        return `${this.uploadingFileLength} ${this.$t(`filesCounter.${this.uploadingFileLength > 4 ? 'moreThanFour' : 'lessThanFour'}`)}`
      }
      return `${this.$t('Supported formats:')} PDF, Word, JPG, PNG`
    }
  },
  methods: {
    openFileMenu () {
      this.$refs.file.$refs.input.click()
    },
    onDrop (e) {
      this.dragover = false
      this.upload(Array.from(e.dataTransfer.files))
      // Array.from(e.dataTransfer.files).forEach((element) => {
      //   const isAllowedExtension = Object.values(this.extensionsMap).includes(element.type)
      //
      //   if (isAllowedExtension) {
      //     console.log(e.dataTransfer.files)
      //     this.upload(Array.from(e.dataTransfer.files))
      //   } else {
      //     this.$handlers.error('File format is not allowed to download', this)
      //   }
      // })
    },
    resetUploadForm () {
      this.hasError = false
      this.$refs.uploader.reset()
      this.uploadingFileName = null
      this.uploadingFileLength = null
    },
    async upload (files) {
      this.resetUploadForm()
      const formattedFiles = Array.isArray(files) ? files : [files]
      if (formattedFiles.length === 1) {
        this.uploadingFileName = this._.get(formattedFiles, '[0].name')
      } else if (formattedFiles.length > 1) {
        this.uploadingFileLength = this._.get(formattedFiles, 'length')
      }
      const { valid } = await this.$refs.uploader.validate(formattedFiles)
      if (!valid) {
        this.hasError = true
        return
      }
      try {
        this.loading = true
        for (const file of formattedFiles) {
          const arrayBuffer = await this.blobToArrayBuffer(file)
          const uint8View = new Uint8Array(arrayBuffer)
          const base64Str = Base64js.fromByteArray(uint8View)
          try {
            const payload = {
              fileContent: base64Str,
              filename: file?.name,
              title: file?.name.split('.').slice(0, -1).join('') // TODO: or just file.name ?
            }
            const templateId = this._.get(await Template.api().create(payload), 'response.data.id')
            if (formattedFiles.length === 1 && templateId) {
              this.$emit('close')
              await this.$router.push({ path: `/templates/${templateId}`, query: { status: 'new' } })
              return
            }
            await Template.api().read(templateId)
            this.$notification.success(this.$t('{document} has been successfully uploaded', { document: file.name }))
          } catch (e) {
            const message = this._.get(e, 'response.data.message')
            this.$notification.error(this.$t('Error occurred with {document} - {error}', { document: file.name, error: message }))
          }
        }
        this.$emit('close')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.template-uploader {
  border-radius: 12px;
  border: 2px dashed $primary;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), rgba(143, 37, 125, 0.12);

  padding: 40px;
  cursor: pointer;

  &.failed {
    border-color: $danger;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%), rgba(255, 23, 68, 0.05);
  }

  &.loading {
    border-color: $border-secondary;
    background: $background-secondary;
  }

  &__input {
    display: none;
    position: absolute;
  }
}
</style>
