<template lang="pug">
  ValidationProvider(:rules="validationRules" name="uploader" ref="uploader")
    v-btn(
      v-bind="$attrs"
      class="main-button"
      :class="buttonClass"
      :loading="loading"
      @click="openFileMenu"
    )
      slot
    v-file-input(
      v-show="false"
      ref="file"
      type="file"
      :multiple="multiple"
      class="e-input-file"
      :accept="accept"
      @change="$emit('onload', $event)"
    )
</template>

<script>
export default {
  name: 'EButtonUploadFile',
  inheritAttrs: false,
  props: {
    // todo: shouldn`t be here
    gtmEventName: {
      type: String,
      default: 'click_download'
    },
    buttonClass: {
      type: String,
      default: null
    },
    validationRules: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openFileMenu () {
      if (this.gtmEventName) {
        window.dataLayer && window.dataLayer.push({
          event: this.gtmEventName,
          email: this._.get(this.$User, 'email')
        })
      }
      this.$refs.file.$refs.input.click()
    },
    async validate (files) {
      return await this.$refs.uploader.validate(files)
    }
  }
}
</script>

<style lang="scss">
.mobile-fixed-button {
  width: 56px !important;
  height: 56px !important;

  border-radius: 16px;
  box-shadow: 0 2px 40px 0 rgba(3, 1, 2, 0.12);

  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 1;
}
</style>
