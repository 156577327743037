import { mapGetters } from 'vuex'
import contentDialog from '~/mixins/dialogs/contentDialog'
import confirmationDialog from '~/mixins/dialogs/confirmationDialog'
import informationSnackbar from '~/modules/snackbar/mixins/informationSnackbar'
import Tag from '~/modules/tags/models/Tag'
import Document from '~/modules/documents/models/Document'

const tags = {
  mixins: [contentDialog, confirmationDialog, informationSnackbar],
  computed: {
    ...mapGetters('documents', {
      requestParams: 'requestParams'
    }),
    tags () {
      return Tag.all()
    },
    tagActions () {
      return [
        {
          name: 'edit',
          text: 'Edit',
          icon: 'pen-edit',
          call: tag => this.editTag(tag)
        },
        {
          name: 'display',
          text: 'Display',
          icon: 'arrow-right',
          innerMenu: this.displayTagOptions
        },
        {
          name: 'delete',
          text: 'Delete',
          icon: 'danger-trash',
          call: tag => this.deleteTag(tag)
        }
      ]
    },
    displayTagOptions () {
      return [
        {
          text: 'Show',
          value: true,
          call: (tag, value) => this.onTagVisibleChange(tag, value)
        },
        {
          text: 'Hide',
          value: false,
          call: (tag, value) => this.onTagVisibleChange(tag, value)
        }
      ]
    }
  },
  methods: {
    async documentsTableUpdate () {
      await Document.api().filter(this.requestParams).all()
    },
    async getTags () {
      try {
        await Tag.api().all()
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async createTag ({ document = null, documents = null }) {
      await this.contentDialog.open({
        width: '512px',
        component: 'form-tag',
        componentProps: {
          document,
          documents,
          tableRequest: this.documentsTableUpdate
        }
      })
    },
    async editTag (tag) {
      await this.contentDialog.open({
        width: '512px',
        component: 'form-tag',
        componentProps: {
          isEdit: true,
          item: tag,
          tableRequest: this.documentsTableUpdate
        }
      })
    },
    async deleteTag (tag) {
      await this.confirmationDialog.open({
        title: this.$t('Remove tag'),
        text: this.$t('Documents are labeled by this tag {tagName} will not deleted', { tagName: tag.name }),
        buttonText: {
          approve: 'Delete',
          dismiss: 'Cancel'
        },
        icon: {
          src: require('~/assets/images/warning.png'),
          maxWidth: 80
        },
        onConfirm: async () => {
          await Tag.api().del(tag)
          if (this.$route.params.id === this.tag.id) {
            await this.$router.push({ name: 'documents' })
          } else {
            await this.documentsTableUpdate()
          }
          await this.successSnackbar.open({
            text: this.$t('Tag has been successfully deleted')
          })
        }
      })
    },
    async toggleDocumentTag ({ id, tagId, tagName, isDelete, loading }) {
      try {
        loading[tagId] = true
        const payload = {
          tagIds: [tagId]
        }
        if (isDelete) {
          await Document.api().deleteTag(id, tagId)
          await this.informationSnackbar.open({
            text: this.$t('Tag {tagName} has deleted from document', {
              tagName
            }),
            buttonText: 'Cancel',
            buttonTextVariant: true,
            onButtonClick: async () => {
              try {
                await Document.api().addTags(id, payload)
                await Document.api().read(id)
              } catch (e) {
                this.$handlers.error(e, this)
              }
            }
          })
        } else {
          await Document.api().addTags(id, payload)
          await this.informationSnackbar.open({
            text: this.$t('Tag {tagName} has added to document', {
              tagName
            }),
            buttonText: 'Cancel',
            buttonTextVariant: true,
            onButtonClick: async () => {
              try {
                await Document.api().deleteTag(id, tagId)
                await Document.api().read(id)
              } catch (e) {
                this.$handlers.error(e, this)
              }
            }
          })
        }
        await Document.api().read(id)
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        loading[tagId] = false
      }
    },
    async addTagToDocuments ({ documentIds, tagId, tagName, loading }) {
      try {
        loading[tagId] = true
        const payload = {
          tagIds: [tagId],
          documentIds
        }
        await Document.api().attachTags(payload)
        await this.documentsTableUpdate()
        this.$emit('clear')
        await this.informationSnackbar.open({
          text: this.$t('Tag {tagName} has added to documents', {
            tagName
          }),
          buttonText: 'Have a look',
          buttonClass: 'main-button',
          onButtonClick: () => {
            this.$router.push({ name: 'tags-id', params: { id: tagId } })
          }
        })
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        loading[tagId] = false
      }
    },
    async onTagVisibleChange (item, value) {
      if (value === item.showInDocuments) {
        return
      }
      const payload = { ...item, showInDocuments: !item.showInDocuments }
      try {
        await Tag.api().update(item, payload)
        await this.documentsTableUpdate()
        // todo: maybe show some message
      } catch (e) {
        this.$handlers.error(e, this)
      }
    }
  }
}

export default tags
