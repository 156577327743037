import { render, staticRenderFns } from "./introduction-guideline.vue?vue&type=template&id=c5676d5e&scoped=true&lang=pug&"
import script from "./introduction-guideline.vue?vue&type=script&lang=js&"
export * from "./introduction-guideline.vue?vue&type=script&lang=js&"
import style0 from "./introduction-guideline.vue?vue&type=style&index=0&id=c5676d5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5676d5e",
  null
  
)

export default component.exports