import Base64js from 'base64-js'
import edsIitCheckboxDialog from '~/modules/eds-iit-checkbox/mixins/edsIitCheckboxDialog'

const getEdsIitInterfaceOutside = {
  mixins: [edsIitCheckboxDialog],
  methods: {
    async getEdsIitSignInterface (documents) {
      const edsIitInterface = await this.useEdsIitCheckboxDialog({
        type: 'edsIitCheckboxCommon',
        method: 'open',
        keyCheck: false,
        saveDataInterfaceAfterClose: true,
        pluginTitles: {
          options: this.$t('signPlugin.signWith')
        },
        onConfirm: async ({ dialogClosed, isDiia, isSmartId, isDepositSign, sign }) => {
          if (dialogClosed) {
            return
          }
          if (isDiia || isSmartId || isDepositSign) {
            let payload
            const signOptions = { keyCheck: false }
            if (isDiia) {
              payload = this.createPayloadForCloudProvider(documents, 'diia')
            } else if (isSmartId || isDepositSign) {
              signOptions.isHashed = true
              payload = this.createPayloadForCloudProvider(documents, 'smartId')
            }
            return await sign(payload, signOptions)
          }
        }
      })
      if (!edsIitInterface) {
        return
      }
      return edsIitInterface
    },
    createPayloadForCloudProvider (documents, provider) {
      const filesToSign = []
      for (const document of documents) {
        let file
        if ('fileHash' in document) {
          file = Base64js.toByteArray(this._.get(document, 'fileHash', ''))
        } else {
          file = Base64js.toByteArray(this._.get(document, 'file', ''))
        }
        if (provider === 'smartId' || provider === 'depositSign') {
          filesToSign.push(file)
        } else if (provider === 'diia') {
          filesToSign.push({
            name: this._.get(document, 'title'),
            content: file,
            isHashed: true,
            externalId: this._.get(document, 'id')
          })
        }
      }
      return filesToSign
    }
  }
}

export default getEdsIitInterfaceOutside
