<template lang="pug">
  div(class="form-document-share")
    div(v-if="!xsDevice" class="form-document-share__header")
      h2(class="form-document-share__title mb-8") {{ $t('Share this document') }}
    div(class="form-document-share__content")
      v-form
        div(class="form-document-share__add")
          div(class="form-document-share__add-content")
            v-tooltip(
              v-model="showWrongEdrpouTooltip"
              bottom
              nudge-top="35"
              min-width="350"
              nudge-left="40"
              content-class="form-document-share__tooltip-content"
            )
              template(v-slot:activator="data")
                ValidationObserver(ref="email" slim class="w-100")
                  ValidationProvider(:rules="emailInputRules" vid="email" v-slot="{ errors }" name="Email" mode="lazy")
                    v-text-field(
                      v-model.trim="email"
                      filled
                      :error-messages="errors"
                      :loading="inputLoading"
                      :label="$t('Enter user email or EDRPOU')"
                      class="form-document-share__add-input"
                      :hide-details="xsDevice"
                      :hint="$t('Add user email or EDRPOU and click +')"
                      @keyup.native.enter="addUser"
                    )
                      template(v-slot:append)
                        v-btn(v-if="email && !errors.length" icon @click="addUser")
                          e-svg-icon circle-primary-plus
              div(class="form-document-share__tooltip pa-2")
                v-btn(icon class="form-document-share__tooltip-close" @click="showWrongEdrpouTooltip = false")
                  e-svg-icon cross-close
                div(class="form-document-share__tooltip-title") {{ $t('Send document to E-mail') }}
                div(class="form-document-share__tooltip-text") {{ $t('This organization is not yet registered in Dubidoc, we recommend sending the document to E-mail') }}
          div(class="form-document-share__add-action")
            v-select(
              v-model="action"
              :items="formattedParticipantActions"
              filled
              :menuProps="{ bottom: true, offsetY: true }"
            )
      div(class="form-document-share__label pb-2" :class="{ 'pb-4': !xsDevice }") {{ $t('Users with access') }}
      div(v-if="!participantsLoading" class="form-document-share__participants")
        div(class="form-document-share__add")
          div(class="form-document-share__add-content")
            div
              div(class="form-document-share__icon form-document-share__icon--gray")
                v-img(:src="require('~/assets/images/person.png')" max-width="16")
            div
              div(class="form-document-share__label") {{ xsDevice ? $t('You (Owner)') : $t('You') }}
              div(class="form-document-share__email") {{ $User.email }}
          div(v-if="!xsDevice" class="form-document-share__add-action")
            div(class="form-document-share__add-owner") {{ $t('Owner') }}
        div(class="form-document-share__add" v-for="user in participants" :key="user.id")
          div(class="form-document-share__add-content")
            div
              div(class="form-document-share__icon form-document-share__icon--gray")
                v-img(:src="require('~/assets/images/person.png')" max-width="16")
            div
              div(class="form-document-share__label") {{ getParticipantName(user) }}
              div(v-if="user.edrpou" class="form-document-share__email") {{ $t('Edrpou') }} {{ user.edrpou }}
            div(v-if="xsDevice" class="d-flex justify-end w-100")
              v-btn(icon disabled class="form-document-share__action-icon--disabled")
                e-svg-icon(size="sm") arrow-down
              v-btn(v-if="user.status !== 'signed'" icon @click="deleteParticipant(user.id)" :loading="participantsDeleteLoading[user.id]")
                e-svg-icon trash
          div(v-if="!xsDevice" class="form-document-share__add-action")
            v-btn(v-if="user.status !== 'signed'" class="form-document-share__delete" icon @click="deleteParticipant(user.id)" :loading="participantsDeleteLoading[user.id]")
              e-svg-icon trash
            v-select(
              :items="participantActions"
              :value="actionByRoleMap[user.role]"
              filled
              hide-details
              dense
              disabled
              :menuProps="{ bottom: true, offsetY: true }"
            )
        div(class="form-document-share__add" v-for="(user) in newUsers" :key="user.id")
          div(class="form-document-share__add-content")
            div
              div(class="form-document-share__icon form-document-share__icon--gray")
                v-img(:src="require('~/assets/images/person.png')" max-width="16")
            div
              div(class="form-document-share__label") {{ user.name || user.email }}
              div(v-if="user.edrpou" class="form-document-share__email") {{ $t('Edrpou') }} {{ user.edrpou }}
            div(v-if="xsDevice" class="d-flex justify-end w-100")
              v-btn(icon disabled class="form-document-share__action-icon--disabled")
                e-svg-icon(size="sm") arrow-down
              v-btn(icon @click="deleteUser(user.id)")
                e-svg-icon trash
          div(v-if="!xsDevice" class="form-document-share__add-action")
            v-btn(class="form-document-share__delete" icon @click="deleteUser(user.id)")
              e-svg-icon trash
            v-select(
              v-model="user.action"
              :items="formattedParticipantActions"
              filled
              hide-details
              dense
              :menuProps="{ bottom: true, offsetY: true }"
            )
      div(v-else class="d-flex align-center justify-center")
        e-progress-circular(
          size="lg"
          width="4"
          color="#161b25"
        )
      div(class="form-document-share__label pb-2" :class="{ 'pb-4': !xsDevice }") {{ $t('Public access') }}
      div(class="form-document-share__add")
        div(class="form-document-share__add-content")
          div
            div(class="form-document-share__icon" :class="{ 'form-document-share__icon--green': isPublicAccess }")
              e-svg-icon(:name="isPublicAccess ? 'globe' : 'lock'")
          div(class="w-100")
            div(v-if="xsDevice" class="form-document-share__label") {{ isPrivateAccess ? $t('Private access') : $t('Everyone, who has link') }}
            v-select(
              v-if="!xsDevice"
              v-model="documentPrivacy"
              :items="documentAccesses"
              :menuProps="{ bottom: true, offsetY: true }"
              :loading="deleteLinkLoading"
              dense
              height="20"
              hide-details
              class="form-document-share__select"
              :class="{'form-document-share__select--private': isPrivateAccess }"
            )
            div(class="form-document-share__email form-document-share__email--margin") {{ isPrivateAccess ? $t('Only users with access to open this document') : $t('Anybody with document link') }}
            div(v-if="xsDevice" class="form-document-share__label form-document-share__label--light") {{ publicAction === 'view' ? $t('Can view') : $t('Can sign') }}
          div(v-if="xsDevice" class="d-flex justify-end")
            v-menu(
              content-class="form-document-share__add-mobile-menu"
              :close-on-content-click="false"
              top
            )
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon v-on="on")
                  e-svg-icon(size="sm") arrow-down
              div(class="form-document-share__add-mobile")
                div(class="form-document-share__email mb-2") {{ $t('Who can open document') }}
                div(class="form-document-share__add-mobile-form")
                  v-btn(
                    class="form-document-share__add-mobile-btn form-document-share__add-mobile-btn--top"
                    height="48"
                    @click="documentPrivacy = 'public'"
                  )
                    e-svg-icon(v-if="documentPrivacy === 'public'" class="form-document-share__add-mobile-btn-icon") check-primary
                    | {{ $t('Everyone, who has link') }}
                  v-btn(
                    class="form-document-share__add-mobile-btn form-document-share__add-mobile-btn--bottom"
                    height="48"
                    @click="documentPrivacy = 'private'"
                  )
                    e-svg-icon(v-if="documentPrivacy === 'private'" class="form-document-share__add-mobile-btn-icon") check-primary
                    | {{ $t('Private access') }}
                div(v-if="isPublicAccess" class="form-document-share__email mb-2 mt-6") {{ $t('User can') }}
                div(v-if="isPublicAccess" class="form-document-share__add-mobile-form")
                  v-btn(
                    class="form-document-share__add-mobile-btn form-document-share__add-mobile-btn--top"
                    @click="publicAction = 'view'"
                  )
                    e-svg-icon(v-if="publicAction === 'view'" class="form-document-share__add-mobile-btn-icon") check-primary
                    | {{ $t('To view') }}
                  v-btn(
                    class="form-document-share__add-mobile-btn form-document-share__add-mobile-btn--bottom"
                    @click="publicAction = 'sign'"
                  )
                    e-svg-icon(v-if="publicAction === 'sign'" class="form-document-share__add-mobile-btn-icon") check-primary
                    | {{ $t('To sign') }}
        div(v-if="isPublicAccess && !xsDevice" class="form-document-share__add-action")
          v-select(
            v-model="publicAction"
            :items="formattedParticipantActions"
            filled
            hide-details
            dense
            :menuProps="{ bottom: true, offsetY: true }"
          )
    div(class="form-document-share__actions")
      v-btn(
        id="copylink"
        height="48"
        :disabled="isPrivateAccess || !documentLink"
        @click="copyToClipboard"
      )
        e-svg-icon(class="mr-2" :class="{ 'form-document-share__action-icon--disabled': isPrivateAccess }") link
        | {{ xsDevice ? $t('Copy') : $t('Copy link') }}
      v-btn(
        id="send"
        :loading="loading"
        :disabled="disableSubmit"
        class="main-button"
        @click="submit"
      ) {{ hasAddedUsers ? $t('Send') : $t('Ready') }}
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import ESvgIcon from '~/components/elements/icons/e-svg-icon'
import Document from '~/modules/documents/models/Document'
import EProgressCircular from '~/components/elements/progress/e-progress-circular'
import clipboard from '~/mixins/global/_clipboard'
import Participant from '~/modules/documents/models/Participant'
import responsive from '~/mixins/pages/responsive'
import participants from '~/modules/documents/mixins/participants'

export default {
  name: 'FormDocumentShare',
  components: {
    ESvgIcon,
    ValidationProvider,
    EProgressCircular
  },
  mixins: [participants, clipboard, responsive],
  props: {
    document: {
      type: Object,
      default: null
    },
    closeModal: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      email: null,
      action: 'sign',
      publicAction: 'sign',
      documentPrivacy: null,
      participants: null,
      documentLink: null,
      newUsers: [],
      participantsLoading: false,
      loading: false,
      copyLoading: false,
      inputLoading: false,
      deleteLinkLoading: false,
      participantsDeleteLoading: {},
      hasCopiedLink: false,
      showWrongEdrpouTooltip: false
    }
  },
  computed: {
    model () {
      return Document
    },
    documentAccesses () {
      return [
        {
          text: 'Усі, хто мають посилання',
          value: 'public'
        },
        {
          text: 'Обмежений доступ',
          value: 'private'
        }
      ]
    },
    isPublicAccess () {
      return this.documentPrivacy === 'public'
    },
    isPrivateAccess () {
      return this.documentPrivacy === 'private'
    },
    emailInputRules () {
      if (isFinite(this.email)) {
        return 'min:8|max:10'
      }
      return 'email'
    },
    hasAddedUsers () {
      return this._.get(this.newUsers, 'length') || 0
    },
    disableSubmit () {
      return !this.hasAddedUsers && !this.hasCopiedLink
    },
    formattedParticipantActions () {
      if (this.document.canFillFields) {
        return this.participantActions
      }
      return this._.filter(this.participantActions, action => action.value !== 'fill')
    },
    actionByRoleMap () {
      return {
        [this.model.USER_ROLES.viewer]: this._.find(this.participantActions, action => action.value === 'view'),
        [this.model.USER_ROLES.signer]: this._.find(this.participantActions, action => action.value === 'sign'),
        [this.model.USER_ROLES.filler]: this._.find(this.participantActions, action => action.value === 'fill')
      }
    }
  },
  watch: {
    email () {
      if (this.showWrongEdrpouTooltip || this.$refs.email.errors?.email.length) {
        this.showWrongEdrpouTooltip = false
        this.$refs.email.reset()
      }
    },
    documentPrivacy (val, prev) {
      if (this.isPrivateAccess && prev) {
        this.deleteLink()
      }
      if (this.isPublicAccess) {
        this.generateLink()
        window.dataLayer && window.dataLayer.push({
          event: 'click_publicshare_link',
          email: this._.get(this.$User, 'email')
        })
      }
    },
    publicAction () {
      this.generateLink()
    }
  },
  async created () {
    await this.getParticipants()
    this.documentPrivacy = this._.get(this.document, 'hasPublicAccess') ? 'public' : 'private'
    for (const participant of this.participants) {
      this.$set(this.participantsDeleteLoading, participant.id, false)
    }
  },
  mounted () {
    window.dataLayer && window.dataLayer.push({
      event: 'publicpage_view',
      email: this._.get(this.$User, 'email')
    })
  },
  methods: {
    copyToClipboard () {
      try {
        this.copy(this.documentLink)
        this.hasCopiedLink = true
        this.$notification.success(this.$t('Copied to clipboard'))
        window.dataLayer && window.dataLayer.push({
          event: 'copy_link',
          email: this._.get(this.$User, 'email')
        })
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async addUser () {
      const valid = await this.$refs.email.validate()
      if (!valid) {
        return
      }
      try {
        this.inputLoading = true
        const payload = { email: this.email, action: this.action }
        const isEdrpou = isFinite(this.email) && this.email.length >= 8 && this.email.length <= 10
        const isDuplicate = this.newUsers.find(user => this._.get(user, 'email') === this.email) || this.participants.find(user => this._.get(user, 'user.email') === this.email)
        const isOwner = this.email === this.$User.email
        const isDuplicateEdrpou = this.newUsers.find(user => this._.get(user, 'edrpou') === this.email) || this.participants.find(user => this._.get(user, 'organization.edrpou') === this.email)
        if (isDuplicate || isOwner) {
          this.$refs.email.setErrors({
            email: [this.$t('This email is already taken')]
          })
          return
        }
        if (isDuplicateEdrpou) {
          this.$refs.email.setErrors({
            email: [this.$t('This edrpou is already taken')]
          })
          return
        }
        if (isEdrpou) {
          payload.edrpou = this.email
          delete payload.email
        }
        const newUser = this._.get(await this.model.api().sendParticipants(this.document.id, payload, { validate: true }), 'response.data')
        const newUserPayload = { ...payload, id: newUser.id, name: this._.get(newUser, 'organization.name', null) }
        this.newUsers.push(newUserPayload)
        this.email = null
      } catch (e) {
        const errorCode = this._.get(e, 'response.data.status') || this._.get(e, 'response.data.code')
        if (errorCode === 400) {
          this.$refs.email.setErrors({
            email: ['']
          })
          this.showWrongEdrpouTooltip = true
        } else {
          this.$handlers.error(e, this)
        }
      } finally {
        this.inputLoading = false
      }

      window.dataLayer && window.dataLayer.push({
        event: 'add_email',
        email: this._.get(this.$User, 'email')
      })
    },
    async getParticipants () {
      try {
        this.participantsLoading = true
        this.participants = this._.get(await this.model.api().getParticipants(this.document.id), 'response.data')
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.participantsLoading = false
      }
    },
    deleteUser (userId) {
      this.newUsers = this._.filter(this.newUsers, user => user.id !== userId)
    },
    async deleteParticipant (id) {
      try {
        this.participantsDeleteLoading[id] = true
        // todo: rework to model base delete
        await Participant.api().del({ $id: id })
        this.participants = this._.filter(this.participants, p => p.id !== id)
        this.$notification.success(this.$t('Participant has successfully deleted'))
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.participantsDeleteLoading[id] = false
      }
    },
    async generateLink () {
      try {
        this.documentLink = this._.get(await this.model.api().generateDocumentLink(this.document.id, { action: this.publicAction }), 'response.data.link')
        this.updateDocumentAccess(true)
      } catch (e) {
        this.$handlers.error(e, this)
      }
    },
    async deleteLink () {
      try {
        this.copyLoading = true
        await this.model.api().deleteDocumentLink(this.document.id)
        this.updateDocumentAccess(false)
        this.documentLink = null
      } catch (e) {
        this.$handlers.error(e, this)
      } finally {
        this.copyLoading = false
      }
    },
    updateDocumentAccess (value) {
      this.model.update({
        where: this.document.id,
        data: {
          hasPublicAccess: value
        }
      })
    },
    async submit () {
      this.loading = true
      for (const user of this.newUsers) {
        delete user.id
        if (!user.name) {
          delete user.name
        }
        try {
          await this.model.api().sendParticipants(this.document.id, user)
        } catch (e) {
          this.$handlers.error(e, this)
        }
      }
      window.dataLayer && window.dataLayer.push({
        event: 'document_sent',
        email: this._.get(this.$User, 'email')
      })
      if (this.hasAddedUsers) {
        this.$notification.success(this.$t('Invitations have been sent'))
      }
      if (this.xsDevice) {
        this.$emit('close')
      } else {
        this.closeModal(this.hasAddedUsers)
      }
      this.loading = false
    },
    getParticipantName (p) {
      return p.edrpou ? this._.get(p, 'organization.name') : this._.get(p, 'user.email')
    }
  }
}
</script>

<style scoped lang="scss">
.form-document-share {
  &__title {
    margin-bottom: 8px;

    font-size: 32px !important;
    font-weight: 700;
    line-height: 40px;
    color: #000;
  }
  &__participants {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  &__text {
    margin-bottom: 32px;

    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.75);
  }
  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #000;

    &--light {
      font-weight: 300;
    }
  }
  &__add {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;

    @media (min-width: map-get($breakpoints, 'sm')) {
      flex-direction: row;
    }

    &-content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      position: relative;

      @media (min-width: map-get($breakpoints, 'sm')) {
        width: auto;
      }
    }
    &-input {
      &::v-deep {
        .v-input__slot {
          padding-right: 0 !important;
        }
      }
    }
    &-action {
      width: 100%;

      position: relative;

      @media (min-width: map-get($breakpoints, 'sm')) {
        max-width: 170px;
      }
    }
    &-owner {
      width: 100%;
      height: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
  &__add-mobile {
    width: 100%;
    border-radius: 16px;
    background: #FFF;
    padding: 16px;

    &-menu {
      max-width: 95%;
      width: 100%;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.12);
    }

    &-btn {
      width: 100%;
      position: relative;

      &-icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &--top {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0.5px solid $background-secondary;
      }
      &--bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  &__icon {
    width: 48px;
    height: 48px;
    border-radius: 12px;

    margin-right: 16px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: #030102E0;

    &--green {
      background: #27AE60;
    }
    &--black {
      background: #030102E0;
    }
    &--gray {
      background: #F7F7F7;
    }
  }
  &__email {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.50);

    &--margin {
      margin-top: -5px;
    }
  }
  &__select {
    max-width: 215px;
    &::v-deep {
      .v-icon {
        margin-top: 0 !important;
        margin-left: -30px;
      }
    }

    &--private {
      &::v-deep {
        .v-icon {
          margin-top: 0 !important;
          margin-left: -90px;
        }
      }
    }
  }
  &__actions {
    padding-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @media (max-width: map-get($breakpoints, 'sm')) {
      border-top: 0.5px solid rgba(0, 0, 0, 0.08);
      padding: 0 16px;
      background: #ffffff;
      height: 80px;

      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 15;
    }

    .v-btn {
      align-self: center;
    }
  }
  &__action {
    &-icon {
      &--disabled {
        opacity: 0.26;
      }
    }
  }
  &__delete {
    position: absolute;
    top: 50%;
    left: -45px;
    z-index: 1;

    transform: translateY(-50%);
  }
  &__tooltip {
    position: relative;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background: $dark;
      transform: rotateZ(45deg);
      position: absolute;
      top: -6px;
      left: 0;
      z-index: 1;
    }

    &-content {
      pointer-events: auto;
      background: $dark;
    }

    &-close {
      width: 25px;
      height: 25px;
      position: absolute;
      top: 0;
      right: -5px;
      z-index: 9999;
    }
    &-title {
      color: $white;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;

      margin-bottom: 8px;
    }
    &-text {
      color: rgba(255, 255, 255, 0.64);
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }
  }
}
</style>
